export const SIGN_IN = "/signin"

export const routesAuth = [
  {
    name: "Search",
    link: "/search",
    activeIndex: 0,
  },
  {
    name: "Favorites",
    link: "/favorites",
    activeIndex: 1,
  },
  {
    name: "Market Insights",
    link: "/marketinsights",
    activeIndex: 2,
  },
  {
    name: "My Listings",
    link: "/mylistings",
    activeIndex: 3,
  },
  {
    name: "Logout",
    link: "#",
    activeIndex: 4,
  },
]

export const routesNonAuth = [
  {
    name: "Login",
    link: "/login",
    activeIndex: 1,
  },
]
