import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const ColoredLine = ({ color, text }) => {
  const classes = useStyles()

  if (typeof text === "undefined") {
    return <div className={classes.line} />
  } else {
    return (
      <div className={classes.line}>
        <span className={classes.text}>{text}</span>
      </div>
    )
  }
}

const useStyles = makeStyles(theme => ({
  line: {
    borderTop: "1px solid #ccc",
    height: "0px",
  },
  text: {
    backgroundColor: "white",
    padding: "0px 15px",
    display: "block",
    width: "20px",
    margin: "-10px auto 0",
    fontSize: "12px",
    color: "#484848",
  },
}))

export default ColoredLine
