import React from "react"
import { AuthUserContext } from "../Session"
import Navbar from "../Navbar"
import { routesAuth, routesNonAuth } from "../../constants/routes"

const Navigation = () => {
  return (
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? (
          <NavigationAuth authUser={authUser} />
        ) : (
          <NavigationNonAuth />
        )
      }
    </AuthUserContext.Consumer>
  )
}

const NavigationAuth = ({ authUser }) => <Navbar routes={routesAuth} />

const NavigationNonAuth = () => <Navbar routes={routesNonAuth} />

export default Navigation
