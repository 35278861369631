import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { Tabs, Tab, Button } from "@material-ui/core"
import ColoredLine from "../components/ColoredLine"
import logo from "../images/logo.svg"
import { Link } from "gatsby"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import MenuIcon from "@material-ui/icons/Menu"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { withFirebase } from "./Firebase"

const Navbar = ({ firebase, routes }) => {
  const classes = useStyles()
  const theme = useTheme()
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
  const matches = useMediaQuery(theme.breakpoints.down("sm"))

  const [openDrawer, setOpenDrawer] = useState(false)

  const [value, setValue] = useState(0)
  const [open, setOpen] = React.useState(false)

  const handleChange = (e, newValue) => {
    if (e.target.innerHTML === "Logout" && firebase) {
      firebase.doSignOut()
    }
    // if (e.target.innerHTML === "Login") {
    //   setOpen(true)
    // }
    setValue(newValue)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    ;[...routes].forEach(route => {
      switch (window.location.pathname) {
        case `${route.link}`:
          if (value !== route.activeIndex) {
            setValue(route.activeIndex)
          }
          break
        default:
          break
      }
    })
  }, [value, routes])

  const tabs = (
    <React.Fragment>
      <Tabs
        value={value}
        onChange={handleChange}
        className={classes.tabContainer}
        indicatorColor="secondary"
      >
        {routes.map((route, index) => (
          <Tab
            key={`${route}${index}`}
            className={classes.tab}
            name={route.name}
            component={Link}
            to={route.link}
            label={route.name}
          />
        ))}
      </Tabs>
    </React.Fragment>
  )

  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        anchor="right"
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        onOpen={() => {
          setOpenDrawer(true)
        }}
      >
        {/* <div className={classes.toolbarMargin} /> */}
        <List className={classes.drawer}>
          {routes.map(route => (
            <ListItem
              className={classes.drawerItem}
              onClick={() => {
                setOpenDrawer(false)
                setValue(route.activeIndex)
              }}
              key={`${route}${route.activeIndex}`}
              button
              component={Link}
              to={route.link}
              selected={value === route.activeIndex}
            >
              <ListItemText>{route.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
      <IconButton
        disableRipple
        onClick={() => {
          setOpenDrawer(!openDrawer)
        }}
        className={classes.drawerIconContainer}
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <AppBar
        className={classes.appBar}
        position="fixed"
        elevation={0}
        color="secondary"
      >
        <Toolbar disableGutters>
          <Button
            component={Link}
            disableRipple
            to="/"
            onClick={() => {
              setValue(0)
              setOpenDrawer(false)
            }}
            className={classes.logoContainer}
          >
            <img className={classes.logo} src={logo} alt="Fisherly logo" />
          </Button>
          {matches ? drawer : tabs}
        </Toolbar>
        <ColoredLine color="#E3E3E3" />
      </AppBar>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  toolbarMargin: {
    minHeight: "4em",
  },
  tabContainer: {
    marginLeft: "auto",
  },
  tab: {
    ...theme.typography.tab,
    letterSpacing: "0.02em",
    minWidth: 10,
    marginLeft: "5px",
  },
  logo: {
    position: "relative",
    height: "3em",
    paddingLeft: "5px",
  },
  logoContainer: {
    position: "relative",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIcon: {
    width: "40px",
    height: "40px",
  },
  drawer: {
    minWidth: "50vw",
  },
  drawerItem: {
    textAlign: "left",
  },
  appBar: {
    // zIndex: theme.zIndex.modal + 1,
  },
}))

export default withFirebase(Navbar)
